import React from 'react';
import classes from './Title.module.scss';

function Title() {
  return (
    <div className={classes.root}>
      <div className={classes.title}>Pablo Anaya</div>
      <div className={classes.subtitle}>Product Owner & Web Developer</div>
    </div>
  );
}

export default Title;
