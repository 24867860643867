import React from 'react';
import classes from './Avatar.module.scss';

function Avatar() {
  return (
    <img
      className={classes.avatar}
      sizes="
        (min-width: 1200px) 500px,
        (min-width: 900px) calc(33.93vw + 100px),
        (min-width: 760px) 41.67vw,
        19.77vw
      "
      srcSet="
        avatar_ihl1oy_c_scale,w_200.webp 200w,
        avatar_ihl1oy_c_scale,w_546.webp 546w,
        avatar_ihl1oy_c_scale,w_865.webp 865w
      "
      src="avatar_ihl1oy_c_scale,w_865.webp"
      alt="Avatar of Pablo Anaya"
    />
  );
}

export default Avatar;
