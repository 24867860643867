import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Avatar from './components/Avatar';
import Footer from './components/Footer';
import Title from './components/Title';
import Background from './components/Background';

import classes from './App.module.scss';
import RedirectToIndex from './components/RedirectToIndex';

function App() {
  return (
    <Router>
      <div className={classes.root}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Background />
                <main className={classes.main}>
                  <Avatar />
                  <Title />
                </main>
                <Footer />
              </>
            }
          />
          <Route path="*" element={<RedirectToIndex />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
