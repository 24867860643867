import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectToIndex() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default RedirectToIndex;
