import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import App from './App';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBHBrtdxEQK2pUB0HxEWzVLTiMo7IStDiA',
  authDomain: 'pablo-anaya.firebaseapp.com',
  projectId: 'pablo-anaya',
  storageBucket: 'pablo-anaya.appspot.com',
  messagingSenderId: '299077572589',
  appId: '1:299077572589:web:df8ed62e9e64b8ec0df9e4',
  measurementId: 'G-88XWFNEJ1N',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
