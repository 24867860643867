import React from 'react';
import classes from './Footer.module.scss';

function Footer() {
  return (
    <footer className={classes.footer}>
      <div className={classes.copyright}>
        <span>Pablo Anaya © {new Date().getFullYear()}</span>
        <span className={classes.mail}>me@pabloanaya.com</span>
      </div>
      <div className={classes.links}>
        <span>
          <a
            href="https://www.linkedin.com/in/pjanaya/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </span>
        <span>
          <a href="https://github.com/pjanaya" target="_blank" rel="noreferrer">
            GitHub
          </a>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
